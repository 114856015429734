import { auth, db } from "../config/main_config"

export const tosCheckFunction = async function () {
    auth.onAuthStateChanged(function (user) {
        if (user) {
            db.collection("users").doc(user.uid).get().then(function (doc) {
                if (doc.exists) {
                    if (!window.location.href.includes('overduetasks')) {
                        let companyID = doc.data().organisations[0];
                        db.collection("customers").doc(companyID).get().then(function (companyDoc) {
                            if (companyDoc.exists) {
                                let tosMap = companyDoc.data()?.tosMap;
                                if (tosMap !== undefined) {
                                    let isTosAccepted = tosMap.isTosAccepted;
                                    if (isTosAccepted !== true && auth.currentUser.email === companyDoc.data().customerOwner) {
                                        let tosModalDiv = document.createElement('div');
                                        let tosModalContent = document.createElement('div');
                                        let tosTextDiv = document.createElement('div');
                                        let tosH2 = document.createElement('h2');
                                        let tosP = document.createElement('p');
                                        let tosButtonsDiv = document.createElement('div');
                                        let tosAcceptButton = document.createElement('button');
                                        let tosCancelButton = document.createElement('button');
                                        tosH2.innerText = 'Welcome to de.iterate';
                                        tosP.innerHTML = 'Before you continue, please click accept below to acknowledge and agree to our <a href="https://deiterate.com/terms-and-conditions" target="_blank">Terms of Service</a> & <a href="https://deiterate.com/privacy-policy" target="_blank">Privacy Policy</a>.';
                                        tosAcceptButton.innerText = 'Accept';
                                        tosCancelButton.innerText = 'Decline';
                                        tosButtonsDiv.appendChild(tosCancelButton);
                                        tosButtonsDiv.appendChild(tosAcceptButton);
                                        tosTextDiv.appendChild(tosH2);
                                        tosTextDiv.appendChild(tosP);
                                        tosModalContent.appendChild(tosTextDiv);
                                        tosModalContent.appendChild(tosButtonsDiv);
                                        tosModalDiv.appendChild(tosModalContent);
                                        document.body.appendChild(tosModalDiv);
                                        tosModalDiv.classList.add("tosModalDiv");
                                        tosModalContent.classList.add("tosModalContent");
                                        tosTextDiv.classList.add("tosTextDiv");
                                        tosButtonsDiv.classList.add("tosButtonsDiv");
                                        tosModalDiv.style.display = 'flex';
                                        tosCancelButton.addEventListener('click', function () {
                                            auth.signOut();
                                            window.location.href = "/login";
                                        })
                                        tosAcceptButton.addEventListener('click', function () {
                                            $.getJSON('https://api.db-ip.com/v2/free/self').then((response) => {
                                                let ipAddress = response.ipAddress;
                                                db.collection("customers").doc(companyID).set({
                                                    tosMap: {
                                                        isTosAccepted: true,
                                                        acceptanceData: {
                                                            dateAccepted: new Date(),
                                                            ipAddress: ipAddress,
                                                            firstName: doc.data().firstName,
                                                            lastName: doc.data().lastName,
                                                            userID: auth.currentUser.uid,
                                                            userAgent: navigator.userAgent
                                                        }
                                                    }
                                                }, {merge: true}).then(() => {
                                                    tosModalDiv.style.display = 'none';
                                                })
                                            })
                                        })
                                    }
                                }
                            }
                        })
                    }
                }
            })
        }
    })
}